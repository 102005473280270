import { Col, Row } from 'react-bootstrap'
import { assetURL, buildHref } from '~/utils'

import { AppCommonProps } from 'types/app'
import Link from 'next/link'
import React from 'react'
import { routes } from '~/router'
import { withI18n } from '~/hocs'
import Image from 'next/image'

const ListCollections = ({ lng, collections = [], className }: AppCommonProps) => {
  return (
    <div className={className}>
      <Row>
        {
          collections.filter((c: any) => c.videos.length).map((c: any) => (
            <Col className="hover hover-bg-secondary py-2 mb-0 mb-md-3 mb-md-0" xs={6} sm={6} md={3} lg={2} key={c.id}>
              <Link href={buildHref({ pathname: routes.collection.href, query: { id: c.id, name: encodeURIComponent(c.name) }, lng })}>
                <div className="preview-item preview-item-fixed mx-auto" key={c.id}>

                  <div className="preview-item-imgs">
                    {
                      c.videos.map((v: any) => (
                        <div className="preview-item-img-container" key={v.id}>
                          {/* <img className="preview-item-img h-100" src={assetURL(`${v.pv_216}.jpg`)} alt={v.id} /> */}
                          <Image className="preview-item-img h-100" src={assetURL(`${v.pv_216}.jpg`)} layout="fill" alt={v.id} />
                        </div>
                      ))
                    }
                  </div>
                  <div className="preview-item-title ellipsis w-100">{c.name}</div>

                </div>
              </Link>
            </Col>
          ))
        }
      </Row>
    </div>
  )
}
export default withI18n(ListCollections)
