import { Col, Row } from 'react-bootstrap'
import { assetURL, buildHref } from '~/utils'

import { AppCommonProps } from 'types/app'
import React from 'react'
import { routes } from '~/router'
import { useRouter } from 'next/router'
import { withI18n } from '~/hocs'
import Image from 'next/image'

const ListCollections = ({ t, lng, categories = [], className }: AppCommonProps) => {
  const router = useRouter()

  const handleRedirect = (c: any) => {
    router.push(buildHref({
      pathname: routes.category.href,
      query: { id: c.id, name: encodeURIComponent(c.name) },
      lng,
    }),
    undefined, { shallow: true },
    )
  }

  return (
    <div className={className}>
      <Row>
        {
          categories.filter((c: any) => c.videos.length >= 4).map((c: any) => {
            const { videos } = c
            return (
              <Col className="hover hover-bg-secondary mb-0 mb-md-3 py-2" xs={6} sm={4} md={3} lg={3} onClick={() => handleRedirect(c)} key={c.id}>
                <div className="preview-item">
                  <div>
                    <img style={{ width: '100%' }} src={assetURL(`${videos[0].pv_216}.jpg`)} alt={videos[0].id} />
                    <div className="d-flex preview-item-imgs-category">
                      {
                        videos.slice(1, 4).map((v: any) => (
                          <div className="preview-item-img-container" key={v.id}>
                            {/* <img className="preview-item-img w-100" src={assetURL(`${v.pv_216}.jpg`)} alt="" /> */}
                            <Image className="preview-item-img w-100" src={assetURL(`${v.pv_216}.jpg`)} layout="fill" alt={v.id} />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="preview-item-title">{c.name}</div>
                </div>
              </Col>
            )
          },
          )
        }
      </Row>
    </div>
  )
}
export default withI18n(ListCollections)
